<template>
  <b-card class="mb-4" no-body>
    <template #header>
      <h4>{{ title }}</h4>
    </template>
    <b-card-body>
      <b-row>
        <b-col class="col-12" v-if="period_info">
          <p class="text-muted">{{ period_info }}</p>
        </b-col>
        <b-col class="col-12">
          <apexchart
            :type="chart.type"
            :options="chart.options"
            :series="chart.data"
            :height="options.chart.height"
          ></apexchart>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    chartType: { type: String, required: true },
    options: { type: Object, required: true },
    data: { type: Array, required: true },
    period_info: { type: String, default: null },
  },
  data() {
    return {
      card: {
        title: null,
      },
      chart: {
        type: null,
        options: null,
        data: null,
      },
    };
  },
  created() {
    this.card.title = this.title;
    this.chart.type = this.chartType;
    this.chart.options = this.options;
    this.chart.data = this.data;
  },
};
</script>
